import React, { useContext, useEffect, useRef } from 'react';
import {
    Badge,
    CircularProgress,
    FormHelperText,
    Grid,
    Grow,
    IconButton,
    InputAdornment,
    InputLabel,
    Paper,
    Popover,
    Tooltip,
    Typography
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DateTimePicker, SecondaryButton, SecondaryCheckbox, SelectBox, TextBox } from '../../../Core/FormInput';
import PartHeader from './partHeader';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DialogComp from '../../../Core/Modal/dialogModal';
import PartSupplier from './partSupplier';
import { CustomIconButton, PrimaryButton } from '../../../Core/FormInput/AppButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CircularIndeterminate from '../ReportsScreens/ReportsScreens/BudgetCore/loader';
import PartReturnAddUpdate from './partReturn';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import SearchIcon from '@material-ui/icons/Search';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import PartRequesterSignature from './PartReqSignatures';
import PartRequisitionsIssueQuantity from '../part-reqs-qty-issue';
import { PartSupplierContext, PartSupplierProvider as StateProvider } from './partSupplierProvider';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PartReqSupplierPhotoModal from './ImageUploader';
import HistoryIcon from '@material-ui/icons/History';
import PartReqHistory from './history';
import AssociatedMaterial from './../../technician/technician-app/FaultandAssociate/assciateMaterial';
import EventIcon from '@material-ui/icons/Event';
import { Skeleton } from '@material-ui/lab';
import ApproverSignature from './PartReqSignatures/approverSignature';
import { AppStorage } from '../../../Core/Service/storage-service';

const useStyles = makeStyles({
    table: {
        minWidth: 1460
    },
    input: {
        ' .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '0.5px',
            paddingBottom: '2.5px'
        }
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        padding: '0px  5px',
        fontWeight: 600
        // border: '1px solid'
    },
    body: {
        border: 'none',
        padding: '5px'
        // borderBottom: '0.5px solid  lightgrey'
    }
}))(TableCell);
const StyledSubTableCell = withStyles((theme) => ({
    head: {
        padding: '0px  5px',
        fontWeight: 600
        // border: '1px solid'
    },
    body: {
        borderBottom: '2px solild lightgrey',
        padding: '5px'
        // borderBottom: '0.5px solid  lightgrey'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const StyledTableMainRow = withStyles((theme) => ({
    // root: {
    //     '&:nth-of-type(1+1)': {
    //         backgroundColor: ''
    //     }
    // }
}))(TableRow);

const SimpleCell = withStyles((theme) => ({
    head: {
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 12
    }
}))(TableCell);

const MatchingPartList = ({ list, loading, handleRowSelect }) => {
    return (
        <Paper style={{ borderRadius: '5px', maxHeight: '350px', overflow: 'auto', width: '350px' }}>
            <TableContainer>
                <Table size="small" width="550px" stickyHeader>
                    <TableHead>
                        <TableRow className="header">
                            <SimpleCell>Number</SimpleCell>
                            <SimpleCell>Description</SimpleCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((j, i) => (
                            <StyledTableRow className={`row${j.isSelected ? ' is-selected-j' : ''}`} onClick={() => handleRowSelect(j)}>
                                <SimpleCell>{j.partNumber}</SimpleCell>
                                <SimpleCell>{j.description}</SimpleCell>
                            </StyledTableRow>
                        ))}
                        {list?.length === 0 && (
                            <TableRow>
                                <TableCell colSpan="4">{loading ? 'Loading...' : 'No record found.'}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

const ETAModal = (props) => {
    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <DateTimePicker name="partReqETA" />
                </Grid>
            </Grid>
        </div>
    );
};

const GlobalCss = withStyles({
    '@global': {
        '.MuiOutlinedInput-inputMarginDense': {
            paddingTop: '4.1px',
            paddingBottom: '4.1px',
            backgroundColorL: 'white'
        },

        '.MuiOutlinedInput-adornedEnd ': {
            paddingRight: '0px',
            backgroundColor: 'white'
        },
        '.MuiOutlinedInput-adornedStart ': {
            paddingLeft: '3px',
            backgroundColor: 'white'
        },
        '.MuiOutlinedInput-input': {
            background: 'white'
        }
    }
})(() => null);

const PartReqSupplier = (props) => {
    const {
        state,
        setState,
        partList,
        setPartList,
        supplierPart,
        setSupplierPart,
        anchorEl,
        anchorE2,
        status,
        PullData,
        handleInputPart,
        onPartReturnSubmit,
        handleCost,
        handleClose,
        AddRow,
        handleInputSupplierCheck,
        handleSignatureModal,
        handleSubmit,
        handleMainClose,
        urlParams,
        fromStep,
        navigateToDashboard,
        handleRowSelect,
        pullDataByPartNumber,
        handleClosePOP,
        removeRow,
        showPartReturnModal,
        handleImage,
        handleHistory,
        onHistoryClose,
        handleETAModal,
        handleStatusInputPart,
        onImagemodalClose,
        errors,
        checkApprover,
        closeApproval
    } = useContext(PartSupplierContext);

    const isAllowDeletePartReqToUser = AppStorage.getCanDeletePartRequests();

    let TableHeaders = [
        // { width: '1%', name: '', isValid: false },
        // { width: '4%', name: 'Is Fitted' },
        { width: '27%', name: 'Description', isValid: true },
        { width: '8%', name: 'Part Number', isValid: true },
        { width: '5%', name: 'QTY', isValid: true },
        { width: '8%', name: 'Cost', isValid: false },
        { width: '6%', name: 'Retail Price', isValid: false },
        { width: '5%', name: 'Labour Hours', isValid: false },
        { width: '5%', name: 'Labour Value', isValid: false },
        { width: '5%', name: 'Is Warranty', isValid: false },
        { width: '14%', name: 'Surcharge', isValid: false },
        { width: '9%', name: 'Status', isValid: false },
        {
            width: '2%',
            name: (
                <IconButton size="small" onClick={AddRow} disabled={state.isReadOnly}>
                    <AddCircleIcon style={{ color: state.isReadOnly ? 'grey' : 'green' }} />
                </IconButton>
            )
        }
    ];

    const classes = useStyles();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const open2 = Boolean(anchorE2);
    const id2 = open ? 'simple-popover2' : undefined;

    const Qtyref = useRef(null);

    useEffect(() => {
        if (Qtyref.current && state.partIndex > -1) {
            setTimeout(() => {
                Qtyref.current?.focus();
            }, 1000);
        }
    }, [state.isFocusFlag]);

    return (
        <div style={{ padding: 20 }}>
            <GlobalCss />
            {state.isReload ? (
                <Grid container alignItems="center" justifyContent="center" style={{ height: 500 }}>
                    <Grid item>
                        <CircularIndeterminate />
                    </Grid>
                </Grid>
            ) : (
                <Grid container justifyContent="space-between" spacing={2}>
                    <PartHeader jobDetails={state.jobDetails} partList={partList} setPartList={setPartList} headerRecordID={state.headerRecordID} />
                    <Grid item xs={12}>
                        <TableContainer style={{ height: !fromStep ? 'calc(100vh - 270px)' : 'calc(100vh - 160px)', backgroundColor: 'white' }}>
                            <Table className={classes.table} aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {TableHeaders.map((val, index) => (
                                            <StyledTableCell key={`val${index}`} width={val.width}>
                                                <span style={{ color: val.isValid ? 'red' : 'black' }}>{val.name}</span>
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {partList.map((row, index) => {
                                        if (!row.isDeleted) {
                                            return (
                                                <React.Fragment key={index}>
                                                    <StyledTableMainRow
                                                    //  style={{ background: index === state.RowID ? '#f6efeb' : '' }}
                                                    >
                                                        {/* <StyledTableCell>
                                                            {index !== state.RowID ? (
                                                                <KeyboardArrowRightIcon
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setState((st) => ({
                                                                            ...st,
                                                                            RowID: index
                                                                        }));
                                                                    }}
                                                                />
                                                            ) : (
                                                                <KeyboardArrowRightIcon
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setState((st) => ({
                                                                            ...st,
                                                                            RowID: null
                                                                        }));
                                                                    }}
                                                                />
                                                            )}
                                                        </StyledTableCell> */}
                                                        {/* <StyledTableCell>
                                                            <SecondaryCheckbox
                                                                onChange={handleInputSupplierCheck(index)}
                                                                name="isFitted"
                                                                checked={row.isFitted}
                                                                disabled={state.isReadOnly || row.isfittedDisabled}
                                                            />
                                                        </StyledTableCell> */}
                                                        <StyledTableCell>
                                                            <TextBox
                                                                placeholder="Description"
                                                                name="partReqPartDescription"
                                                                value={row.partReqPartDescription}
                                                                onChange={handleInputPart(index)}
                                                                disabled={state.isReadOnly}
                                                                autoFocus={true}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <CustomIconButton
                                                                                tabIndex={-1}
                                                                                // toolTipTitle="Get Vehicle Details"
                                                                                onClick={pullDataByPartNumber(row.partReqPartDescription, 'desc', index)}
                                                                                disabled={state.isReadOnly}
                                                                            >
                                                                                <SearchIcon />
                                                                            </CustomIconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                error={row.ErrorDescription}
                                                            />
                                                            <FormHelperText error shrink>
                                                                {row.ErrorDescription}
                                                            </FormHelperText>

                                                            <Popover
                                                                id={id2}
                                                                open={open2}
                                                                anchorEl={anchorE2}
                                                                onClose={handleClosePOP}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'center'
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center'
                                                                }}
                                                            >
                                                                <MatchingPartList
                                                                    list={state.matchingPartNumber || []}
                                                                    loading={state.loading}
                                                                    handleRowSelect={handleRowSelect}
                                                                />
                                                            </Popover>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TextBox
                                                                style={{ padding: 0 }}
                                                                placeholder="Part Number"
                                                                name="partReqPartNumber"
                                                                value={row.partReqPartNumber}
                                                                // autoFocus={true}
                                                                disabled={state.isReadOnly}
                                                                onChange={handleInputPart(index)}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <CustomIconButton
                                                                                disabled={state.isReadOnly}
                                                                                tabIndex={-1}
                                                                                onClick={pullDataByPartNumber(row.partReqPartNumber, 'part', index)}
                                                                            >
                                                                                <SearchIcon />
                                                                            </CustomIconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                error={row.ErrorPartNumber}
                                                            />
                                                            <FormHelperText error shrink>
                                                                {row.ErrorPartNumber}
                                                            </FormHelperText>
                                                            <Popover
                                                                id={id}
                                                                open={open}
                                                                anchorEl={anchorEl}
                                                                onClose={handleClosePOP}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'center'
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center'
                                                                }}
                                                            >
                                                                <MatchingPartList
                                                                    list={state.matchingPartNumber || []}
                                                                    loading={state.loading}
                                                                    handleRowSelect={handleRowSelect}
                                                                />
                                                            </Popover>
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            <TextBox
                                                                placeholder="Qty"
                                                                name="partReqQuantity"
                                                                value={row.partReqQuantity}
                                                                disabled={state.isReadOnly}
                                                                onChange={handleInputPart(index)}
                                                                inputRef={state.partIndex == index ? Qtyref : null}
                                                                // autoFocus={true}
                                                                error={row.ErrorQuantity}
                                                            />
                                                            <FormHelperText error shrink>
                                                                {row.ErrorQuantity}
                                                            </FormHelperText>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TextBox
                                                                placeholder="Cost"
                                                                name="partReqsCost"
                                                                disabled={state.isReadOnly}
                                                                value={row.partReqsCost}
                                                                onChange={handleInputPart(index)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <CustomIconButton disabled={state.isReadOnly}>
                                                                                <ShoppingCartIcon
                                                                                    style={{
                                                                                        color: !state.isReadOnly ? 'green' : 'grey',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => handleCost(row, index)}
                                                                                />
                                                                            </CustomIconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TextBox
                                                                disabled={state.isReadOnly}
                                                                placeholder="Price"
                                                                name="partReqsRetailPrice"
                                                                value={row.partReqsRetailPrice}
                                                                onChange={handleInputPart(index)}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TextBox
                                                                disabled={state.isReadOnly}
                                                                placeholder="Hours"
                                                                name="partReqsLabourHours"
                                                                value={row.partReqsLabourHours}
                                                                onChange={handleInputPart(index)}
                                                            />
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            <TextBox
                                                                disabled={state.isReadOnly}
                                                                placeholder="Value"
                                                                name="partReqsLabourValue"
                                                                value={row.partReqsLabourValue}
                                                                onChange={handleInputPart(index)}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <SecondaryCheckbox
                                                                disabled={state.isReadOnly}
                                                                onChange={handleInputSupplierCheck(index)}
                                                                name="partReqFMWarranty"
                                                                checked={row.partReqFMWarranty}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item xs={2}>
                                                                    <SecondaryCheckbox
                                                                        // disabled={state.isReadOnly}
                                                                        onChange={handleInputSupplierCheck(index)}
                                                                        name="partReqSurcharged"
                                                                        checked={row.partReqSurcharged}
                                                                        disabled={row.returnStatus || state.isReadOnly}
                                                                    />
                                                                </Grid>
                                                                {/* {row.partReqSurcharged && ( */}
                                                                <Grid item xs={6}>
                                                                    <TextBox
                                                                        placeholder="Value"
                                                                        name="partReqSurchargedValue"
                                                                        type="number"
                                                                        onChange={handleInputPart(index)}
                                                                        value={row.partReqSurchargedValue}
                                                                        disabled={!row.partReqSurcharged || row.returnStatus}
                                                                    />
                                                                </Grid>
                                                                {/* )} */}
                                                                {row.returnStatus && (
                                                                    <Grid item xs={4}>
                                                                        <SecondaryButton
                                                                            fullWidth
                                                                            disabled={state.isReadOnly}
                                                                            style={{
                                                                                padding: '2px 6px',
                                                                                background: row.returnStatus === 'Credit Received' ? 'green' : 'red',
                                                                                fontSize: 10
                                                                            }}
                                                                            size="small"
                                                                            onClick={() => showPartReturnModal(row)}
                                                                        >
                                                                            {row.returnStatus}
                                                                        </SecondaryButton>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <SelectBox
                                                                disabled={state.isReadOnly}
                                                                List={status}
                                                                name="partReqStatus"
                                                                onChange={handleStatusInputPart(index, row)}
                                                                value={row.partReqStatus}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {(isAllowDeletePartReqToUser || !row.partReqStatus) && (
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12}>
                                                                        <IconButton size="small" onClick={() => removeRow(index)} disabled={state.isReadOnly}>
                                                                            <RemoveCircleIcon style={{ color: state.isReadOnly ? 'grey' : 'red' }} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                        </StyledTableCell>
                                                    </StyledTableMainRow>
                                                    {/* {index === state.RowID && ( */}
                                                    {/* <Grow in={index === state.RowID}> */}
                                                    <StyledTableMainRow>
                                                        {/* <StyledTableCell colSpan={1}></StyledTableCell> */}
                                                        <StyledSubTableCell colSpan={1}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <InputLabel>Availability</InputLabel>
                                                                    <DateTimePicker
                                                                        disabled={state.isReadOnly}
                                                                        style={{ marginTop: 6 }}
                                                                        placeholder="Availability"
                                                                        name="partReqsAvailability"
                                                                        value={row.partReqsAvailability}
                                                                        onChange={handleInputPart(index)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </StyledSubTableCell>
                                                        <StyledSubTableCell colSpan={1}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <InputLabel>Supplier</InputLabel>
                                                                    <TextBox
                                                                        disabled={state.isReadOnly}
                                                                        placeholder="Supplier"
                                                                        name="partReqSupplier"
                                                                        value={row.partReqSupplier}
                                                                        onChange={handleInputPart(index)}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </StyledSubTableCell>
                                                        <StyledSubTableCell colSpan={2}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <InputLabel>Order Number</InputLabel>
                                                                    <TextBox
                                                                        disabled={state.isReadOnly}
                                                                        placeholder="Order Number"
                                                                        name="partReqsOrderNumber"
                                                                        value={row.partReqsOrderNumber}
                                                                        onChange={handleInputPart(index)}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </StyledSubTableCell>
                                                        <StyledSubTableCell colSpan={2}>
                                                            <PrimaryButton
                                                                disabled={state.isReadOnly}
                                                                size="small"
                                                                onClick={() => {
                                                                    setState((sy) => ({ ...sy, showIssueModal: true }));
                                                                }}
                                                                fullWidth
                                                                style={{ marginTop: 17 }}
                                                            >
                                                                Issue Parts
                                                            </PrimaryButton>
                                                        </StyledSubTableCell>

                                                        <StyledSubTableCell colSpan={2}>
                                                            <Grid
                                                                container
                                                                spacing={1}
                                                                alignItems="center"
                                                                justifyContent="flex-start"
                                                                style={{ marginTop: 10 }}
                                                                disabled={state.isReadOnly}
                                                            >
                                                                <Grid item>
                                                                    {/* <PrimaryButton size="small" style={{}} onClick={handleSignatureModal(index, row)}> */}
                                                                    <Tooltip title="Signatures">
                                                                        <BorderColorIcon
                                                                            disabled={state.isReadOnly}
                                                                            onClick={handleSignatureModal(index, row)}
                                                                            style={{ fontSize: 19, cursor: 'pointer', marginTop: 6 }}
                                                                        />
                                                                    </Tooltip>
                                                                    {/* </PrimaryButton> */}
                                                                </Grid>

                                                                {row.partReqID ? (
                                                                    <Grid item>
                                                                        <Tooltip title="Images">
                                                                            <Badge badgeContent={row.photosUploaded} color="error">
                                                                                <PhotoCameraIcon
                                                                                    disabled={state.isReadOnly}
                                                                                    onClick={handleImage(row)}
                                                                                    style={{ fontSize: 19, cursor: 'pointer' }}
                                                                                />
                                                                            </Badge>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                ) : null}

                                                                <Grid item>
                                                                    <Tooltip title="History">
                                                                        <HistoryIcon
                                                                            disabled={state.isReadOnly}
                                                                            onClick={handleHistory(row)}
                                                                            style={{ fontSize: 19, cursor: 'pointer', marginTop: 5 }}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title="ETA">
                                                                        <EventIcon
                                                                            disabled={state.isReadOnly}
                                                                            onClick={() => handleETAModal(index, row)}
                                                                            style={{ fontSize: 19, cursor: 'pointer', marginTop: 5 }}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </StyledSubTableCell>
                                                        <StyledSubTableCell colSpan={2}>
                                                            {/* <PrimaryButton
                                                                           size="small"
                                                                           onClick={() => handleAssociated(index)}
                                                                           fullWidth
                                                                           style={{ marginTop: 17 }}
                                                                       >
                                                                           Associated Materials
                                                                       </PrimaryButton> */}
                                                        </StyledSubTableCell>

                                                        <StyledSubTableCell colSpan={3}></StyledSubTableCell>
                                                    </StyledTableMainRow>
                                                    {/* </Grow> */}
                                                    {/* )} */}
                                                </React.Fragment>
                                            );
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={6}>
                        {state.submitLoader ? (
                            <SecondaryButton fullWidth>
                                <CircularProgress size={25} />
                            </SecondaryButton>
                        ) : (
                            <SecondaryButton fullWidth onClick={handleSubmit}>
                                Submit
                            </SecondaryButton>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <SecondaryButton fullWidth onClick={handleMainClose}>
                            Cancel
                        </SecondaryButton>
                    </Grid>
                </Grid>
            )}

            {state.showSignatureModal ? (
                <DialogComp title={'Signature'} onClose={handleSignatureModal(null, {})}>
                    <PartRequesterSignature
                        partSignIndex={state.partSignIndex}
                        partSignObj={state.partSignObj}
                        partList={partList}
                        setPartList={setPartList}
                        message={state.message}
                        onClose={handleSignatureModal(null, {})}
                    />
                </DialogComp>
            ) : null}
            {state.showIssueModal ? (
                <DialogComp
                    maxWidth="xl"
                    fullWidth
                    onClose={() => {
                        setState((sy) => ({ ...sy, showIssueModal: false }));
                    }}
                >
                    <PartRequisitionsIssueQuantity
                        headerRecordID={state.headerRecordID}
                        urlParams={urlParams}
                        fromStep={true}
                        navigateToDashboard={navigateToDashboard}
                        isModal={true}
                        onClose={() => {
                            setState((sy) => ({ ...sy, showIssueModal: false }));
                        }}
                    />
                </DialogComp>
            ) : null}

            {state.showImage && (
                <DialogComp title="Image Upload" onClose={() => onImagemodalClose(false)} fullWidth maxWidth="lg">
                    <PartReqSupplierPhotoModal
                        partId={state.partId}
                        onFormSubmit={() => PullData()}
                        desc={state.desc}
                        onClose={() => onImagemodalClose(false)}
                        disabled={state.isReadOnly}
                    />
                </DialogComp>
            )}
            {state.showHistory && (
                <DialogComp title="History" onClose={onHistoryClose} fullWidth maxWidth="lg">
                    <PartReqHistory partHistoryId={state.partId} onClose={onHistoryClose} />
                </DialogComp>
            )}

            {state.showSupplier ? (
                <DialogComp title={'Supplier'} maxWidth="xl" fullWidth onClose={handleClose}>
                    <PartSupplier
                        onClose={handleClose}
                        supplierPart={supplierPart}
                        setSupplierPart={setSupplierPart}
                        rows={state.costRow}
                        partList={partList}
                        setPartList={setPartList}
                        jobDetails={state.jobDetails}
                    />
                </DialogComp>
            ) : null}
            {state.showPartReturn ? (
                <DialogComp title={'Advice Of Return'} maxWidth="lg" onClose={handleClose}>
                    <PartReturnAddUpdate selectedData={state.selectedData} onClose={onPartReturnSubmit} jobDetails={state.jobDetails} PullPartData={PullData} />
                </DialogComp>
            ) : null}
            {/* {state.isShowAssociate ? (
                <DialogComp title={'Advice Of Return'} maxWidth="md" fullWidth onClose={handleAssociated}>
                    <AssociatedMaterial
                        onClose={handleAssociated}
                        list={partList[state.partSignIndex].associatedMaterials}
                        handleAssociatedRow={handleAssociatedRow}
                        removeAssociatedRow={removeAssociatedRow}
                        handleAssociateInput={handleAssociateInput}
                        isReadOnly={state.isTechReadonly}
                        setInputList={setPartList}
                        modalIndex={state.partSignIndex}
                    />
                </DialogComp>
            ) : null} */}
            {state.isShowETA ? (
                <DialogComp title={'ETA (Estimated time of Arrival)'} maxWidth="lg" onClose={handleETAModal}>
                    <div style={{ padding: 10 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DateTimePicker
                                    disabled={state.isReadOnly}
                                    name="partReqETA"
                                    value={partList[state.partSignIndex].partReqETA}
                                    onChange={handleInputPart(state.partSignIndex)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SecondaryButton fullWidth onClick={handleETAModal} disabled={state.isReadOnly}>
                                    {' '}
                                    Ok
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </div>
                </DialogComp>
            ) : null}
            {state.shoWApproval ? (
                <DialogComp title={'Approval Signature'} onClose={() => closeApproval(false)}>
                    <ApproverSignature checkApprover={checkApprover} onClose={() => closeApproval(true)} partList={partList} setPartList={setPartList} />
                </DialogComp>
            ) : null}
        </div>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <PartReqSupplier {...props} />
        </StateProvider>
    );
};
export default Screen;
